.custom-icon {
  font-size: 24px;
  color: #4acaa8;
  text-align: center;
}

.cards_emotions_icons{
  align-items: center;
  text-align: center;
}

#Salud.custom-icon {
  font-size: 24px;
  text-align: center;
}

#Amor.mt-4{
  margin-top: 30px !important;
}

#Amor.custom-icon {
  font-size: 24px;
  text-align: center;
  margin-right: 20px;
}

#Dinero.custom-icon {
  font-size: 24px;
  text-align: center;
  margin-left: 20px;
}


.icon-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Amor.asd_column {
  background-color: #ca684a;
  border-bottom-left-radius: 6px;
}

#Salud.asd_column {
  background-color: #4acaa8;
}

#Dinero.asd_column {
  background-color: #acca4a;
  border-bottom-right-radius: 6px;
}