.div-border {
    margin-top: 30px;
    border: 2px dashed #caa84a;
    padding: 30px;
    border-radius:10px;
}

h3 {
    text-align: center;
    padding-bottom: 10px;
}

h4 {
    font-size: 14px;
    padding-bottom: 10px;
    text-align: center;
}

.info-signo-seleccion p{
    text-align: center !important;
    font-size: 18px;
}

.btn-saber-mas {
    width: 100%;
    margin-right: 5px;
    margin-top: 10px;
    padding: 10px;
    background-color: #ca684a;
    border-radius: 6px;
    color: white;
    border: none;
    margin-bottom: 30px;
}

.btn-saber-mas:hover {
    background-color: #743c2b;
    font-weight: bold;
}

.tu-signo-texto{
    font-weight: bold;
    color: #CA4AAC;
}

.signo-ascendente-texto{
    font-weight: bold;
    color: #4A6CCA;
}

.form-select{
    margin-bottom: 10px;
}