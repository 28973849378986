.footer_bg {
  background-color: #17141d;
  height: auto;
}

.footer_bg p {
  margin-top: 10px;
  color: #caa84a;
  text-align: center;
}


.footer_bg a {
  color: #caa84a;
  text-align: center;
}



.footer-icon {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  display: block;
}

.footer-icon img {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.footer-icon-overload {
  color: #caa84a;
}