.articles-container {
    display: grid;
    gap: 20px;
    padding: 20px;
  }

.header_cards {
  height: 120px;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #715572;
  padding-top: 20px;
}

.header_cards_superpuesto {
  background-color: white;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.header_cards_superpuesto img{
  padding-top: 20%;
  padding-left: 2%;

}

.cards-body{
    height: auto;
    background-color: white;
    padding: 5px;
}
.cards-intro-signs{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    height:auto;
    font-size: 12px;
    margin-top: 10px; 

  }

.cards-link-saber_mas {
    text-decoration: none;
    color: white;
    font-size: 14px;
    text-align: center;
    display: block;
    font-family: 'Montserrat', sans-serif;
    height: auto;
    background-color: #5C5470;
    border-radius: 6px;
    border: none;
    padding: 10px;
    margin-bottom: 5px;
}


.link_body a {
  padding: 10px;
  background-color: #4acaa8;
  border-radius: 6px;
  color: white;
  border: none;
  font-family: 'Montserrat', sans-serif;
}

.cards-link-saber_mas:hover {
    font-weight: bold;
}

.cards_base {
    margin-bottom: 20px;
    height: 12px;
    background-color: #6f8e48;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}