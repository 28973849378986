.horoscopo_container {
  display: grid;
  gap: 20px;
  padding: 20px;
}

.horoscopo_cards_contenedor{
  border-radius: 25px;
  margin-bottom: 20px;
}

#horoscopo_header_cards {
  position: relative;
  height: 120px;
  text-align: center;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-color: black;
  background-color: #B9B4C7;
}

#horoscopo_header_cards_superpuesto {
  background-color: white;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.horoscopo-cards-body {
  background-color: white;
  padding: 10px;
  height: auto;
  width: 100%;

}

.horoscopo-cards-intro-signs {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  height: 340px;
  font-size: 12px;
}

.cards-link-saber_mas:hover {
  font-weight: bold;
}

.horoscopo_cards_base {
  height: 20px;
  background-color: #acca4a;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.horoscopo_title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.horoscopo_subtitle {
  font-size: 24px;
  padding-top: 5px;
  font-weight: bold;
}