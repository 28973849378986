.alerta-fondo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200px;
}

.alerta-fondo p {
  font-size: 16px;
  text-align: center;
}

.alerta-popup {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.alerta-cerrar {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.alerta-botones {
  margin-top: 20px;
}

.alerta-botones button {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 6px;
  color: white;
  border: none;
  font-weight: bold;
}

.button_closed {
  background-color: #ff5252;
  color: white;
}

.button_continue {
  background-color: #4caf50;
  color: white;
}

.button_closed:hover {
  background-color: #d34343;
  color: white;
}

.button_continue:hover {
  background-color: #378039;
  color: white;
}

.icon {
  font-size: 44px;
  text-align: center;
}
.resultados{
  text-align: center;
}