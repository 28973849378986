.asd_columns-container {
  display: inline-block;
  height: 70px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-bottom: 20px;
}



.asd_columns-title {
  text-align: center;
  padding-bottom: 1px;
  margin-top: 1px;
  text-decoration: none;
  color: white;
  font-size: 16px;
  text-align: center;

}

.asd_columns-container img {
  max-width: 100%;

}



.asd_column {
  background-color: #7e226f;
}

.custom-modal {
  width: 90%;
  height: 90%;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
  overflow-y: scroll;
}



#superpuesto img {
  position: absolute;
  width: 30%;
  height: 30%;
}

#superpuesto h2 {
  color: black;
  text-align: center;
  margin-top: 110px;
}
