.content{
   padding: 20px;
   font-family: 'Montserrat', sans-serif;
}

.content h1{
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content h2{
  padding-top: 20px;
  padding-bottom: 10px;
}

.vigencia{
  padding-top: 20px;
}