.ascendente_body{
    padding-top:30px;
    padding-bottom: 40px;
}

.ascendente_body h3{
   text-align: center;
   font-weight: bold;
   margin-bottom: 30px;
}

.ascendente_body p{
    text-align: justify;
}

.btn_sabes_tu_signo{
    width: 100%;
    margin-right: 5px;
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #4acaa8;
    border-radius: 6px;
    color: white;
    border: none;
}

.btn_sabes_tu_signo:hover{
    background-color: #26745f;
    font-weight: bold;
}


.btn_cual_es_tu_signo{
    width: 100%;
    margin-left: 5px;
    padding: 10px;
    margin-top: 30px;
    border-width: 3px;
    background-color: #acca4a;
    border-radius: 6px;
    margin-bottom: 10px;
    border: none;
    color: white;
}

.btn_cual_es_tu_signo:hover{
    background-color:  #819737;
    font-weight: bold;
}