.articulos-container {
  display: grid;
  gap: 20px;
  padding: 20px;
}

.cards_body {
  height: auto;
  background-color: white;
  padding: 5px;

}

.label {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(111, 142, 72, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9em;

}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
  margin-bottom: 15px;
}

.card-img {
  width: 100%;
  height: auto;
}

.h2-size {
  margin-top: 15px;
  font-size: 18px;
  height: 60px;
}

.link_body {
  background-color: white;
  align-items: center;
  height: 50px;
  text-align: center;
  padding-bottom: 55px;
}

.link_body a {
  padding: 12px;
  background-color: #5C5470;
  display: block;
  border-radius: 6px;
  font-size: 14px;
  color: white;
  border: none;
  font-family: 'Montserrat', sans-serif;
}



.link_body a:hover {
  font-weight: bold;
}


.cards_body_info {
  background-color: white;
  padding: 5px;
}

.cards_body_info p {
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 40px;
}

.cards_body_info h3 {
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: bold;

}

.imagenes-signo_img {
  width: 200px !important;
  height: 200px !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 30px;
  border-radius: 50%;
  border: rgb(255, 255, 255) 3px solid;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.cards_body h2 {
  margin-top: 10px;
  text-align: center;
  font-size: 20px;
  height: 100px;
}

.cards_body p {
  margin-top: 20px;
  text-align: justify;
  font-size: 12px;
  height: 200px;
  padding: 10px;
}

.img_container {
  height: 120px;
  align-items: center;
}

.img_container img {
  display: block;
  height: 120px;
  margin: auto;
  border-radius: 10px;
}


#header_cards_articulos {
  height: 120px;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #B9B4C7;
  padding-top: 15px;
}

#header_cards_superpuesto_articulos {
  background-color: white;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

#header_cards_superpuesto_articulos img {
  background-color: white;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: 5px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

}

.column_image {
  height: auto;
  width: auto;
}