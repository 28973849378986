.alerta-fondo-discover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100% !important;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200px;
}

.alerta-popup-discover {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.alerta-popup-discover p {
  text-align: center;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}


.alerta-botones-discover button {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 6px;
  color: white;
  border: none;
  font-weight: bold;
}

.button_search {
  background-color: #4caf50;
  color: white;
}

.button_search:hover {
  background-color: #378039;
  color: white;
}

.resultadoSigno {
  align-items: center;
  border: 2px dashed black;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.resultadoSigno p {
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px!important;
  margin-bottom: auto;
}

.resultadoSigno-link {
  text-decoration: underline;
  color: black;
  font-size: 16px;
  text-align: center;
  display: block;
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 20px;
}

.custom-icon-discover {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #caa84a;
  font-size: 20px;
}

.resultadoSigno-link:hover {
  font-weight: bold;
}