#root {
  background-color: #FAF0E6;
  font-family: 'Montserrat', sans-serif; 
}

.navbar {
    background-color: #352F44;
    overflow: hidden;
    justify-content: start;
    color: #caa84a;

    position: -webkit-sticky; 
    position: sticky !important; 
    top: 0; 
    z-index: 1000; 
}

.icons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.icon {
    font-size: 24px;
}

.navbar a:hover {
    color: #ebebeb;
}

.navbar .title {

    font-size: 20px;
    font-weight: bold;
    margin-right: 30px;
}


.navbar_link.nav-link {
    padding: 5px 0px;
    color: #caa84a;
    font-size: 18px;
    font-weight: bold;
}

 a.title.navbar-brand{
    color: #caa84a;
} 

a.navbar_link.nav-link.active {
    color: #ebebeb;
    text-decoration: none;
}

.navbar .right {
    float: right;
}

.navbar img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

button.navbar-toggler {
    color: #caa84a;
    background-color: #caa84a;
}
.banner_bg {
  width: 100%;
  height: auto;
}

.banner_bg img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

}

.banner-bg-image {
  background-image: url(/static/media/banner_min.a893f472.jpg);
  height: auto;
  padding: 25px;
}

.banner-bg-image h2{
  font-family: Montserrat, sans-serif;
  font-size: 40px;
  text-align: center;
  color: white;
  font-weight: bold ;
}


.banner-bg-image h3 {
  font-family: Montserrat, sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-weight: bold ;
}

h2 span { 
  background-color: #180e30; 
  border-radius: 20px;
}

h3 span { 
  background-color: #180e30; 
  border-radius: 20px;
}



.bienvenido {
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #caa84a;
  font-size: 24px;
}

.bienvenido_a {
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #caa84a;
  font-size: 18px;
}

.soul_title {
  text-align: center;
  font-family: 'Javanese Text', sans-serif;
  color: #caa84a;
  font-size: 60px;
}

.paragraph {
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #caa84a;
  font-size: 18px;
  margin-left: 15%;
  margin-right: 15%;
}

.div-border-banner {
  margin-top: 30px;
  margin-bottom: 30px;
  border: 2px dashed #caa84a;
  padding: 15px;
  border-radius: 10px;
}

.mi-elemento {
  margin-bottom: 20px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mi-elemento img {
  width: 60px;
  height: 60px;

}
.columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
}

.column {
  padding: 10px;
  border-radius: 5px;
}

.column-title {
  text-align: center;
}

article {
  background-color: #fff;
  box-sizing: border-box;
  height: auto;
}

.infoSigns-title {
  font-size: 24px;
  padding-top: 10px;
  text-align: center;
  font-weight: bold;
  background-color: white;
}

.p-date {
  margin-top: 2px;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: -1;
  font-weight: bold;
  height: 50px;
}

.loading_style {
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  float: none;
  padding: 20px;
  text-align: center;
}

.loading_style img {
  align-items: center;
  width: 120px;
  height: 120px;
}


.asd_columns-container {
  display: inline-block;
  height: 70px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-bottom: 20px;
}



.asd_columns-title {
  text-align: center;
  padding-bottom: 1px;
  margin-top: 1px;
  text-decoration: none;
  color: white;
  font-size: 16px;
  text-align: center;

}

.asd_columns-container img {
  max-width: 100%;

}



.asd_column {
  background-color: #7e226f;
}

.custom-modal {
  width: 90%;
  height: 90%;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
  overflow-y: scroll;
}



#superpuesto img {
  position: absolute;
  width: 30%;
  height: 30%;
}

#superpuesto h2 {
  color: black;
  text-align: center;
  margin-top: 110px;
}

.articles-container {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    padding: 20px;
  }

.header_cards {
  height: 120px;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #715572;
  padding-top: 20px;
}

.header_cards_superpuesto {
  background-color: white;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.header_cards_superpuesto img{
  padding-top: 20%;
  padding-left: 2%;

}

.cards-body{
    height: auto;
    background-color: white;
    padding: 5px;
}
.cards-intro-signs{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    height:auto;
    font-size: 12px;
    margin-top: 10px; 

  }

.cards-link-saber_mas {
    text-decoration: none;
    color: white;
    font-size: 14px;
    text-align: center;
    display: block;
    font-family: 'Montserrat', sans-serif;
    height: auto;
    background-color: #5C5470;
    border-radius: 6px;
    border: none;
    padding: 10px;
    margin-bottom: 5px;
}


.link_body a {
  padding: 10px;
  background-color: #4acaa8;
  border-radius: 6px;
  color: white;
  border: none;
  font-family: 'Montserrat', sans-serif;
}

.cards-link-saber_mas:hover {
    font-weight: bold;
}

.cards_base {
    margin-bottom: 20px;
    height: 12px;
    background-color: #6f8e48;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.custom-icon {
  font-size: 24px;
  color: #4acaa8;
  text-align: center;
}

.cards_emotions_icons{
  align-items: center;
  text-align: center;
}

#Salud.custom-icon {
  font-size: 24px;
  text-align: center;
}

#Amor.mt-4{
  margin-top: 30px !important;
}

#Amor.custom-icon {
  font-size: 24px;
  text-align: center;
  margin-right: 20px;
}

#Dinero.custom-icon {
  font-size: 24px;
  text-align: center;
  margin-left: 20px;
}


.icon-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Amor.asd_column {
  background-color: #ca684a;
  border-bottom-left-radius: 6px;
}

#Salud.asd_column {
  background-color: #4acaa8;
}

#Dinero.asd_column {
  background-color: #acca4a;
  border-bottom-right-radius: 6px;
}
.footer_bg {
  background-color: #17141d;
  height: auto;
}

.footer_bg p {
  margin-top: 10px;
  color: #caa84a;
  text-align: center;
}


.footer_bg a {
  color: #caa84a;
  text-align: center;
}



.footer-icon {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  display: block;
}

.footer-icon img {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.footer-icon-overload {
  color: #caa84a;
}
.instagram_body_bg {
    width: 100%;
    height: auto;
    background-color: #736a8b;
    align-items: center;
}

.instagram_body_bg  h2{
    text-align: center;
    font-size: 18px;
    color: white;
    margin: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.instagram_body_bg img {
    width: 20px;
    height: 20px;
    color: white;
}

.miSignoTitle{
    padding-top: 10px;
    color: white;
}

.miSignoTitle p{
    text-align: center;
    font-weight: bold;
    font-size: 18px;
}

.botonesBar{
    padding-bottom: 10px;
}

.botonesBar button{
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
}


.cards-link-saber_mas_discover{
    text-decoration: none;
    color: white;
    font-size: 14px;
    text-align: center;
    display: block;
    font-family: 'Montserrat', sans-serif;
    height: auto;
    background-color: #caa84a;
    border-radius: 6px;
    border: none;
    padding: 10px;
    margin-bottom: 5px;
}

.cards-link-saber_mas_discover:hover {
    font-weight: bold;
  }
  
.alerta-fondo-discover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100% !important;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200px;
}

.alerta-popup-discover {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.alerta-popup-discover p {
  text-align: center;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}


.alerta-botones-discover button {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 6px;
  color: white;
  border: none;
  font-weight: bold;
}

.button_search {
  background-color: #4caf50;
  color: white;
}

.button_search:hover {
  background-color: #378039;
  color: white;
}

.resultadoSigno {
  align-items: center;
  border: 2px dashed black;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.resultadoSigno p {
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px!important;
  margin-bottom: auto;
}

.resultadoSigno-link {
  text-decoration: underline;
  color: black;
  font-size: 16px;
  text-align: center;
  display: block;
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 20px;
}

.custom-icon-discover {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #caa84a;
  font-size: 20px;
}

.resultadoSigno-link:hover {
  font-weight: bold;
}
.articulos-container {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
}

.cards_body {
  height: auto;
  background-color: white;
  padding: 5px;

}

.label {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(111, 142, 72, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9em;

}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
  margin-bottom: 15px;
}

.card-img {
  width: 100%;
  height: auto;
}

.h2-size {
  margin-top: 15px;
  font-size: 18px;
  height: 60px;
}

.link_body {
  background-color: white;
  align-items: center;
  height: 50px;
  text-align: center;
  padding-bottom: 55px;
}

.link_body a {
  padding: 12px;
  background-color: #5C5470;
  display: block;
  border-radius: 6px;
  font-size: 14px;
  color: white;
  border: none;
  font-family: 'Montserrat', sans-serif;
}



.link_body a:hover {
  font-weight: bold;
}


.cards_body_info {
  background-color: white;
  padding: 5px;
}

.cards_body_info p {
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 40px;
}

.cards_body_info h3 {
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: bold;

}

.imagenes-signo_img {
  width: 200px !important;
  height: 200px !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 30px;
  border-radius: 50%;
  border: rgb(255, 255, 255) 3px solid;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.cards_body h2 {
  margin-top: 10px;
  text-align: center;
  font-size: 20px;
  height: 100px;
}

.cards_body p {
  margin-top: 20px;
  text-align: justify;
  font-size: 12px;
  height: 200px;
  padding: 10px;
}

.img_container {
  height: 120px;
  align-items: center;
}

.img_container img {
  display: block;
  height: 120px;
  margin: auto;
  border-radius: 10px;
}


#header_cards_articulos {
  height: 120px;
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #B9B4C7;
  padding-top: 15px;
}

#header_cards_superpuesto_articulos {
  background-color: white;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

#header_cards_superpuesto_articulos img {
  background-color: white;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  margin-top: 5px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

}

.column_image {
  height: auto;
  width: auto;
}
.sub_body_bg {
    width: 100%;
    height: auto;
    background-color: #736a8b;
    align-items: center;
}

.sub_body_bg  h2{
    text-align: center;
    font-size: 20px;
    color: white;
    margin: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
}

.sub_body_bg img {
    width: 20px;
    height: 20px;
    color: white;
}


.allPage {
    background-color: #ffffff;
  }


.body_signo {
    width: 100%;
    
}
.espaciado{
    margin-right: 5px;
}

.header-info-signo {
    padding-top: 20px;
    position: relative;
    height: 90px;
    align-items: center;
    display: block;
}

.header-info-signo img{
    margin: 10px;
    height: 80px;
    width: 80px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.header-info-signo h2 {
    margin-top: 10px;
    color: black;
    font-weight: bold;
    text-align: center;
}

.contenido-temas {
    height: auto;
    padding: 50px;
    padding-top: 150px;
}

.emotions_a{
    padding-top: 20px;
    padding-bottom: 30px;
    text-align: center;
    font-weight: bold;
    align-items: center;
    height: 100px;
    margin-bottom: 30px;
}
.emotions_a a{
   margin-left: 5px;
   background-color: #4acaa8;
   color: #ffffff;
   padding: 10px;
   display: inline;
   border-radius: 6px;
   font-size: 12px;
}

.contenido-temas img {
    margin-right: 10px;
    width: 30px !important;
    height: 30px !important;
}

.contenido-temas h3 {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
}


ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
    text-decoration: none;
}

a {
    text-decoration: none;
    color: #333;
}

#temas a {
    text-decoration: none;
    color: #333;
}

#superpuesto img {
    position: absolute;
    width: 50%;
    height: 50%;
}

#superpuesto h2 {
    color: black;
    text-align: center;
    margin-top: 80%;
}

p {
    text-align: justify;
}

.contenido-temas img {
    width: 3%;
    height: 3%;
}


.contenido-error img{
    margin: 10px;
    height: 180px;
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.contenido-error h4{
    font-weight: bold;
}


.imagenes-signo img{
    width: 150px !important; 
    height: 150px !important; 
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.contenido-ascendente {
    height: auto;
}

.mt-4 {
    margin-top: 3px !important;
}

.contenido-ascendente h3{
    margin-bottom: 10px !important;
}


.saber-signo-ascendente {
    text-decoration: none;
    color: black;
    font-size: 12px;
    text-align: center;
    display: block;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    height: auto;
}

.saber-signo-ascendente:hover {
    color: #caa84a;
}
.div-border {
    margin-top: 30px;
    border: 2px dashed #caa84a;
    padding: 30px;
    border-radius:10px;
}

h3 {
    text-align: center;
    padding-bottom: 10px;
}

h4 {
    font-size: 14px;
    padding-bottom: 10px;
    text-align: center;
}

.info-signo-seleccion p{
    text-align: center !important;
    font-size: 18px;
}

.btn-saber-mas {
    width: 100%;
    margin-right: 5px;
    margin-top: 10px;
    padding: 10px;
    background-color: #ca684a;
    border-radius: 6px;
    color: white;
    border: none;
    margin-bottom: 30px;
}

.btn-saber-mas:hover {
    background-color: #743c2b;
    font-weight: bold;
}

.tu-signo-texto{
    font-weight: bold;
    color: #CA4AAC;
}

.signo-ascendente-texto{
    font-weight: bold;
    color: #4A6CCA;
}

.form-select{
    margin-bottom: 10px;
}
.ascendente_body{
    padding-top:30px;
    padding-bottom: 40px;
}

.ascendente_body h3{
   text-align: center;
   font-weight: bold;
   margin-bottom: 30px;
}

.ascendente_body p{
    text-align: justify;
}

.btn_sabes_tu_signo{
    width: 100%;
    margin-right: 5px;
    margin-top: 30px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #4acaa8;
    border-radius: 6px;
    color: white;
    border: none;
}

.btn_sabes_tu_signo:hover{
    background-color: #26745f;
    font-weight: bold;
}


.btn_cual_es_tu_signo{
    width: 100%;
    margin-left: 5px;
    padding: 10px;
    margin-top: 30px;
    border-width: 3px;
    background-color: #acca4a;
    border-radius: 6px;
    margin-bottom: 10px;
    border: none;
    color: white;
}

.btn_cual_es_tu_signo:hover{
    background-color:  #819737;
    font-weight: bold;
}
.compatibilidad_body {
    padding-top: 30px;
    padding-bottom: 40px;
}

.compatibilidad_body h3 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
}

.info-container {
    height: auto;
    padding: 30px;
    border: 2px dashed #caa84a;
    margin-bottom: 30px;
}

.info-container p {
    text-align: center;
}

.info-container h3 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}

.info-container {
    padding-top: 30px;
}

.compatibilidad-icons {
    align-items: center;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
}

.compatibilidad-icon {
    font-size: 50px;
    color: #caa84a;
    text-align: center;
}

.btn_signo {
    width: 100%;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px;
    background-color: #4acaa8;
    border-radius: 6px;
    color: white;
    border: none;
}

.btn_signo:hover {
    background-color: #26745f;
}

@media (max-width: 576px) {
    .btn-group.w-100 {
        flex-direction: column;
    }
}

.compatibleFeel h3{
    padding-top: 20px;
    font-size: 20px;
}
.horoscopo_container {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
}

.horoscopo_cards_contenedor{
  border-radius: 25px;
  margin-bottom: 20px;
}

#horoscopo_header_cards {
  position: relative;
  height: 120px;
  text-align: center;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-color: black;
  background-color: #B9B4C7;
}

#horoscopo_header_cards_superpuesto {
  background-color: white;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.horoscopo-cards-body {
  background-color: white;
  padding: 10px;
  height: auto;
  width: 100%;

}

.horoscopo-cards-intro-signs {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  height: 340px;
  font-size: 12px;
}

.cards-link-saber_mas:hover {
  font-weight: bold;
}

.horoscopo_cards_base {
  height: 20px;
  background-color: #acca4a;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.horoscopo_title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.horoscopo_subtitle {
  font-size: 24px;
  padding-top: 5px;
  font-weight: bold;
}
.content{
   padding: 20px;
   font-family: 'Montserrat', sans-serif;
}

.content h1{
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.content h2{
  padding-top: 20px;
  padding-bottom: 10px;
}

.vigencia{
  padding-top: 20px;
}
.alerta-fondo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200px;
}

.alerta-fondo p {
  font-size: 16px;
  text-align: center;
}

.alerta-popup {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.alerta-cerrar {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.alerta-botones {
  margin-top: 20px;
}

.alerta-botones button {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 6px;
  color: white;
  border: none;
  font-weight: bold;
}

.button_closed {
  background-color: #ff5252;
  color: white;
}

.button_continue {
  background-color: #4caf50;
  color: white;
}

.button_closed:hover {
  background-color: #d34343;
  color: white;
}

.button_continue:hover {
  background-color: #378039;
  color: white;
}

.icon {
  font-size: 44px;
  text-align: center;
}
.resultados{
  text-align: center;
}
.discover {
    font-family: 'Montserrat', sans-serif;
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
}

.discover h1 {
    padding-top: 20px;
    text-align: center;
    margin-bottom: 30px;
}

.discover h2 {
    padding-top: 20px;
    text-align: center;
    margin-bottom: 10px;
}

.discover p {
    text-align: center;
    font-size: 18px;
}

.botones {
    padding-top: 15px;
    height: 60px !important;
}

.discover .btn_si {
    width: 100%;
    padding: 15px;
    margin-bottom: 5px;
    background-color: #699943;
    border-radius: 6px;
    color: white;
    border: none;
}

.discover .btn_si:hover {
    background-color: #1b5c1e;
    font-weight: bold;
}

.discover .btn_si.activo {
    background-color: #1b5c1e;
    font-weight: bold;
}

.discover .btn_no {
    width: 100%;
    padding: 15px;
    background-color: #b64633;
    border-radius: 6px;
    margin-bottom: 5px;
    color: white;
    border: none;
}

.discover .btn_no:hover {
    background-color: #792c1f;
    font-weight: bold;
}

.discover .btn_no.activo {
    background-color: #792c1f;
    font-weight: bold;
}

.contenedor_cards {
    margin-top: 30px;
    margin-bottom: 30px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.cards-discover {
    padding: 15px;
    background-color: white;
    border-radius: 6px;
}

@media (min-width: 1025px) {
    .cards-discover {
        height: 200px;
    }
    .msgCompletado{
        height: 200px;
    }

    .cards-discover p {
        padding-top: 20px;
        height: 60px;
    }
}

@media (max-width: 768px) {
    .cards-discover {
        height: 240px;
    }
    .msgCompletado{
        height: 240px;
    }

    .cards-discover p {
        padding-top: 20px;
        height: 60px;
    }

}


.cards-discover_bg {
    background-color: #FAF0E6;
}

.cards-discover p {
    font-weight: bold;
}


#header_cards_questions {
    background-size: 45%;
    position: relative;
    height: 75px;
    text-align: center;
    color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-color: black;
    background-color: #715572;
}

#header_cards_superpuesto_questions {
    background-color: #715572;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
}

#header_cards_superpuesto_questions_number {
    background-color: white;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translate(-50%, -50%);
}


.cards_base_questions {
    height: 20px;
    background-color: #6f8e48;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

}

.header_cards_superpuesto_questions_number_h1 {
    color: #715572 !important;
    font-size: 44px;

}


.show_answers {
    width: 100%;
    padding: 15px;
    background-color: #ca684a;
    border-radius: 6px;
    color: white;
    border: none;
    font-weight: bold;
}


.reset_answers {
    width: 100%;
    padding: 15px;
    background-color: #999290;
    border-radius: 6px;
    color: white;
    border: none;
    font-weight: bold;
}

.exclamation {

    color: #caa84a;
}

.sad {
    color: #ff6145;
}


.msgCompletado {
    align-items: center;
    border: 2px dashed black;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: white;
  }

  .msgCompletado p {
    padding-top: 10px;
  }
