.banner_bg {
  width: 100%;
  height: auto;
}

.banner_bg img {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

}

.banner-bg-image {
  background-image: url("../img/banner_min.jpg");
  height: auto;
  padding: 25px;
}

.banner-bg-image h2{
  font-family: Montserrat, sans-serif;
  font-size: 40px;
  text-align: center;
  color: white;
  font-weight: bold ;
}


.banner-bg-image h3 {
  font-family: Montserrat, sans-serif;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  font-weight: bold ;
}

h2 span { 
  background-color: #180e30; 
  border-radius: 20px;
}

h3 span { 
  background-color: #180e30; 
  border-radius: 20px;
}



.bienvenido {
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #caa84a;
  font-size: 24px;
}

.bienvenido_a {
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #caa84a;
  font-size: 18px;
}

.soul_title {
  text-align: center;
  font-family: 'Javanese Text', sans-serif;
  color: #caa84a;
  font-size: 60px;
}

.paragraph {
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #caa84a;
  font-size: 18px;
  margin-left: 15%;
  margin-right: 15%;
}

.div-border-banner {
  margin-top: 30px;
  margin-bottom: 30px;
  border: 2px dashed #caa84a;
  padding: 15px;
  border-radius: 10px;
}

.mi-elemento {
  margin-bottom: 20px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mi-elemento img {
  width: 60px;
  height: 60px;

}