.discover {
    font-family: 'Montserrat', sans-serif;
    display: grid;
    gap: 20px;
    padding: 10px;
}

.discover h1 {
    padding-top: 20px;
    text-align: center;
    margin-bottom: 30px;
}

.discover h2 {
    padding-top: 20px;
    text-align: center;
    margin-bottom: 10px;
}

.discover p {
    text-align: center;
    font-size: 18px;
}

.botones {
    padding-top: 15px;
    height: 60px !important;
}

.discover .btn_si {
    width: 100%;
    padding: 15px;
    margin-bottom: 5px;
    background-color: #699943;
    border-radius: 6px;
    color: white;
    border: none;
}

.discover .btn_si:hover {
    background-color: #1b5c1e;
    font-weight: bold;
}

.discover .btn_si.activo {
    background-color: #1b5c1e;
    font-weight: bold;
}

.discover .btn_no {
    width: 100%;
    padding: 15px;
    background-color: #b64633;
    border-radius: 6px;
    margin-bottom: 5px;
    color: white;
    border: none;
}

.discover .btn_no:hover {
    background-color: #792c1f;
    font-weight: bold;
}

.discover .btn_no.activo {
    background-color: #792c1f;
    font-weight: bold;
}

.contenedor_cards {
    margin-top: 30px;
    margin-bottom: 30px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.cards-discover {
    padding: 15px;
    background-color: white;
    border-radius: 6px;
}

@media (min-width: 1025px) {
    .cards-discover {
        height: 200px;
    }
    .msgCompletado{
        height: 200px;
    }

    .cards-discover p {
        padding-top: 20px;
        height: 60px;
    }
}

@media (max-width: 768px) {
    .cards-discover {
        height: 240px;
    }
    .msgCompletado{
        height: 240px;
    }

    .cards-discover p {
        padding-top: 20px;
        height: 60px;
    }

}


.cards-discover_bg {
    background-color: #FAF0E6;
}

.cards-discover p {
    font-weight: bold;
}


#header_cards_questions {
    background-size: 45%;
    position: relative;
    height: 75px;
    text-align: center;
    color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-color: black;
    background-color: #715572;
}

#header_cards_superpuesto_questions {
    background-color: #715572;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
}

#header_cards_superpuesto_questions_number {
    background-color: white;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translate(-50%, -50%);
}


.cards_base_questions {
    height: 20px;
    background-color: #6f8e48;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

}

.header_cards_superpuesto_questions_number_h1 {
    color: #715572 !important;
    font-size: 44px;

}


.show_answers {
    width: 100%;
    padding: 15px;
    background-color: #ca684a;
    border-radius: 6px;
    color: white;
    border: none;
    font-weight: bold;
}


.reset_answers {
    width: 100%;
    padding: 15px;
    background-color: #999290;
    border-radius: 6px;
    color: white;
    border: none;
    font-weight: bold;
}

.exclamation {

    color: #caa84a;
}

.sad {
    color: #ff6145;
}


.msgCompletado {
    align-items: center;
    border: 2px dashed black;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 10px;
    background-color: white;
  }

  .msgCompletado p {
    padding-top: 10px;
  }