.instagram_body_bg {
    width: 100%;
    height: auto;
    background-color: #736a8b;
    align-items: center;
}

.instagram_body_bg  h2{
    text-align: center;
    font-size: 18px;
    color: white;
    margin: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.instagram_body_bg img {
    width: 20px;
    height: 20px;
    color: white;
}

.miSignoTitle{
    padding-top: 10px;
    color: white;
}

.miSignoTitle p{
    text-align: center;
    font-weight: bold;
    font-size: 18px;
}

.botonesBar{
    padding-bottom: 10px;
}

.botonesBar button{
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
}


.cards-link-saber_mas_discover{
    text-decoration: none;
    color: white;
    font-size: 14px;
    text-align: center;
    display: block;
    font-family: 'Montserrat', sans-serif;
    height: auto;
    background-color: #caa84a;
    border-radius: 6px;
    border: none;
    padding: 10px;
    margin-bottom: 5px;
}

.cards-link-saber_mas_discover:hover {
    font-weight: bold;
  }
  