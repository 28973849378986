.columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
}

.column {
  padding: 10px;
  border-radius: 5px;
}

.column-title {
  text-align: center;
}

article {
  background-color: #fff;
  box-sizing: border-box;
  height: auto;
}

.infoSigns-title {
  font-size: 24px;
  padding-top: 10px;
  text-align: center;
  font-weight: bold;
  background-color: white;
}

.p-date {
  margin-top: 2px;
  text-align: center;
  grid-column-start: 1;
  grid-column-end: -1;
  font-weight: bold;
  height: 50px;
}

.loading_style {
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  float: none;
  padding: 20px;
  text-align: center;
}

.loading_style img {
  align-items: center;
  width: 120px;
  height: 120px;
}

