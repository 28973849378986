.compatibilidad_body {
    padding-top: 30px;
    padding-bottom: 40px;
}

.compatibilidad_body h3 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
}

.info-container {
    height: auto;
    padding: 30px;
    border: 2px dashed #caa84a;
    margin-bottom: 30px;
}

.info-container p {
    text-align: center;
}

.info-container h3 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}

.info-container {
    padding-top: 30px;
}

.compatibilidad-icons {
    align-items: center;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 20px;
}

.compatibilidad-icon {
    font-size: 50px;
    color: #caa84a;
    text-align: center;
}

.btn_signo {
    width: 100%;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px;
    background-color: #4acaa8;
    border-radius: 6px;
    color: white;
    border: none;
}

.btn_signo:hover {
    background-color: #26745f;
}

@media (max-width: 576px) {
    .btn-group.w-100 {
        flex-direction: column;
    }
}

.compatibleFeel h3{
    padding-top: 20px;
    font-size: 20px;
}