.navbar {
    background-color: #352F44;
    overflow: hidden;
    justify-content: start;
    color: #caa84a;

    position: -webkit-sticky; 
    position: sticky !important; 
    top: 0; 
    z-index: 1000; 
}

.icons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.icon {
    font-size: 24px;
}

.navbar a:hover {
    color: #ebebeb;
}

.navbar .title {

    font-size: 20px;
    font-weight: bold;
    margin-right: 30px;
}


.navbar_link.nav-link {
    padding: 5px 0px;
    color: #caa84a;
    font-size: 18px;
    font-weight: bold;
}

 a.title.navbar-brand{
    color: #caa84a;
} 

a.navbar_link.nav-link.active {
    color: #ebebeb;
    text-decoration: none;
}

.navbar .right {
    float: right;
}

.navbar img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
}

button.navbar-toggler {
    color: #caa84a;
    background-color: #caa84a;
}