.allPage {
    background-color: #ffffff;
  }


.body_signo {
    width: 100%;
    
}
.espaciado{
    margin-right: 5px;
}

.header-info-signo {
    padding-top: 20px;
    position: relative;
    height: 90px;
    align-items: center;
    display: block;
}

.header-info-signo img{
    margin: 10px;
    height: 80px;
    width: 80px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.header-info-signo h2 {
    margin-top: 10px;
    color: black;
    font-weight: bold;
    text-align: center;
}

.contenido-temas {
    height: auto;
    padding: 50px;
    padding-top: 150px;
}

.emotions_a{
    padding-top: 20px;
    padding-bottom: 30px;
    text-align: center;
    font-weight: bold;
    align-items: center;
    height: 100px;
    margin-bottom: 30px;
}
.emotions_a a{
   margin-left: 5px;
   background-color: #4acaa8;
   color: #ffffff;
   padding: 10px;
   display: inline;
   border-radius: 6px;
   font-size: 12px;
}

.contenido-temas img {
    margin-right: 10px;
    width: 30px !important;
    height: 30px !important;
}

.contenido-temas h3 {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: left;
}


ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
    text-decoration: none;
}

a {
    text-decoration: none;
    color: #333;
}

#temas a {
    text-decoration: none;
    color: #333;
}

#superpuesto img {
    position: absolute;
    width: 50%;
    height: 50%;
}

#superpuesto h2 {
    color: black;
    text-align: center;
    margin-top: 80%;
}

p {
    text-align: justify;
}

.contenido-temas img {
    width: 3%;
    height: 3%;
}


.contenido-error img{
    margin: 10px;
    height: 180px;
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.contenido-error h4{
    font-weight: bold;
}


.imagenes-signo img{
    width: 150px !important; 
    height: 150px !important; 
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.contenido-ascendente {
    height: auto;
}

.mt-4 {
    margin-top: 3px !important;
}

.contenido-ascendente h3{
    margin-bottom: 10px !important;
}


.saber-signo-ascendente {
    text-decoration: none;
    color: black;
    font-size: 12px;
    text-align: center;
    display: block;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    height: auto;
}

.saber-signo-ascendente:hover {
    color: #caa84a;
}