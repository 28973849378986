.sub_body_bg {
    width: 100%;
    height: auto;
    background-color: #736a8b;
    align-items: center;
}

.sub_body_bg  h2{
    text-align: center;
    font-size: 20px;
    color: white;
    margin: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
}

.sub_body_bg img {
    width: 20px;
    height: 20px;
    color: white;
}

